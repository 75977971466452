import { Button, Dialog, FormCheckbox, GridContainer } from 'canvas-ui-react'
import styled from 'styled-components'

export const Caption = styled.div`
  font-size: 20px;
  font-weight: bold;
`
export const SubCaption = styled.div`
  font-size: 15px;
  color: gray;
`
export const StyledFormCheckbox = styled(FormCheckbox)`
  padding: 3px;
`
export const StyledFormCheckboxJpg = styled(FormCheckbox)`
  margin-left: 20px;
`
export const StyledDialog = styled(Dialog)`
  bottom: 50%;
`
export const StyledGridContainer = styled(GridContainer)`
  min-width: 500px;
`
export const DownloadButton = styled(Button)`
  margin-left: 5px;
`
