import { useState } from 'react'
import Table from 'components/Table'
import { columnDefs, defaultColDef } from './columnDefs'
import { connect } from 'react-redux'
import { getItemsForSearchTable } from 'util/format'
import { GridContainer, GridItem, Heading } from 'canvas-ui-react'
import {
  ResultsGridContainer,
  GridItemTable,
  NotFoundMsg,
  ErrorIcon,
  ItemInfo,
  IconGridItem,
} from './styled'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { DPCIS_NOT_FOUND, DPCIS_WITHOUT_IMAGES } from 'ducks/items'
import EditModal from 'containers/Edit'
import { SEARCH_RESULTS_TABLE } from 'util/constants'

const SearchResults = ({
  items,
  showDialog,
  setErrorList,
  setErrorHeading,
  config,
}) => {
  const [selectedDpci, setSelectedDpci] = useState('')
  const [editModalVisible, setEditModalVisible] = useState(false)

  const openEditModal = (dpci) => {
    setSelectedDpci(dpci)
    setEditModalVisible(true)
  }
  const getContextMenuItems = (params) => {
    let menuItems = [
      {
        name: 'Edit Details',
        icon: '<img width="15" height="15" src="./icons/edit.png"/>',
        action: () => {
          openEditModal(params.node.data.dpci)
        },
      },
    ]
    return menuItems
  }

  const isThereError = (list, errorType) => {
    let length = Object.keys(list).length
    const isDpciMissing = () => errorType === DPCIS_NOT_FOUND

    let dpciErrorText = 'Unable to find '
    let imageErrorText = 'Items without images'
    if (length) {
      return (
        <div>
          <GridContainer spacing="none">
            <GridItem>
              <NotFoundMsg>
                {isDpciMissing() ? dpciErrorText : imageErrorText} {length}
              </NotFoundMsg>
            </GridItem>
            <IconGridItem>
              {isDpciMissing() ? (
                <ErrorIcon
                  icon={faInfoCircle}
                  onClick={() => {
                    setErrorHeading("Following item(s) weren't found")
                    setErrorList(list)
                    showDialog(true)
                  }}
                />
              ) : (
                <ErrorIcon
                  icon={faInfoCircle}
                  onClick={() => {
                    setErrorHeading(
                      "Image(s) weren't found for following item(s)"
                    )
                    setErrorList(list)
                    showDialog(true)
                  }}
                />
              )}
            </IconGridItem>
          </GridContainer>
        </div>
      )
    } else {
      return
    }
  }
  return (
    <div>
      <EditModal
        visible={editModalVisible}
        setVisible={setEditModalVisible}
        dpci={selectedDpci}
      />
      <ResultsGridContainer direction="column">
        <GridItem>
          <ItemInfo>
            <Heading size={6}>
              {Object.keys(items.data).length} Result(s) showing <br />
              {isThereError(items.error.DPCIS_NOT_FOUND, DPCIS_NOT_FOUND)}
              {isThereError(
                items.error.DPCIS_WITHOUT_IMAGES,
                DPCIS_WITHOUT_IMAGES
              )}
            </Heading>
          </ItemInfo>
        </GridItem>
        <GridItemTable>
          <Table
            tableName={SEARCH_RESULTS_TABLE}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={items.data && getItemsForSearchTable(items.data)}
            getContextMenuItems={config.EDIT_ENABLED && getContextMenuItems}
            downloadEnabled={true}
            excelStyles={[
              {
                id: 'textFormat',
                dataType: 'string',
              },
              {
                id: 'header',
                interior: {
                  color: '#CCCCCC',
                  pattern: 'Solid',
                },
                borders: {
                  borderBottom: {
                    color: '#5687f5',
                    lineStyle: 'Continuous',
                    weight: 1,
                  },
                  borderLeft: {
                    color: '#5687f5',
                    lineStyle: 'Continuous',
                    weight: 1,
                  },
                  borderRight: {
                    color: '#5687f5',
                    lineStyle: 'Continuous',
                    weight: 1,
                  },
                  borderTop: {
                    color: '#5687f5',
                    lineStyle: 'Continuous',
                    weight: 1,
                  },
                },
              },
              {
                id: 'bigHeader',
                font: {
                  size: 15,
                },
                alignment: {
                  horizontal: 'Center',
                },
                borders: {
                  borderLeft: {
                    color: 'black',
                    lineStyle: 'Continuous',
                    weight: 2,
                  },
                  borderRight: {
                    color: 'black',
                    lineStyle: 'Continuous',
                    weight: 2,
                  },
                  borderTop: {
                    color: 'black',
                    lineStyle: 'Continuous',
                    weight: 2,
                  },
                  borderBottom: {
                    color: 'black',
                    lineStyle: 'Continuous',
                    weight: 2,
                  },
                },
              },
            ]}
          />
        </GridItemTable>
      </ResultsGridContainer>
    </div>
  )
}
export default connect(({ items, config }) => ({ items, config }))(
  SearchResults
)
