/* eslint-disable no-unused-vars */
import request from 'util/request'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import upperCase from 'upper-case'
import { buildCacheString } from 'util/cache'
import { getUser } from './user'

export const initialState = []

export const GET = 'requests/GET'
export const GET_ALL = 'requests/GET_ALL'
export const REQUEST_SUCCEEDED = 'requests/REQUEST_SUCCEEDED'
export const REQUEST_FAILED = 'requests/REQUEST_FAILED'
export const REQUEST_IF_NOT_CACHED = 'requests/REQUEST_IF_NOT_CACHED'

export default function reducer(state = initialState, action) {
  return state
}

export function _get(resourceType, id, query, history) {
  return {
    type: GET,
    resourceType: upperCase(resourceType),
    id,
    query,
    history,
  }
}

export function _getAll(resourceType, query) {
  return {
    type: GET_ALL,
    resourceType: upperCase(resourceType),
    query,
  }
}

/**
 * Triggers a saga that checks if request is already cached
 *
 * @param {string} requestType An action type constant, e.g. GET, GET_ALL
 * @param {string[]} resourceTypes The resourceTypes we want to load, e.g. PROJECTS
 * @param {object{}} [options] Any options that should be passed to the corresponding API call
 *
 * @example _Cached(GET, [DEPARTMENTS, PROJECTS], [null, {id: 1}])
 * Will send (if not cached) a GET request to DEPARTMENTS and a GET request to PROJECTS.
 * The DEPARTMENTS request will receive no options.
 * The PROJECTS request will recieve options of {id: 1}
 *
 * @return {object} The action to be dispatched
 */
export function _requestIfNotCached(requestType, resourceTypes, options = {}) {
  return {
    type: REQUEST_IF_NOT_CACHED,
    requestType,
    resourceTypes,
    options,
  }
}

export function requestSucceeded(requestType, resourceType, response, details) {
  return {
    type: REQUEST_SUCCEEDED,
    requestType,
    resourceType,
    response,
    details,
  }
}
export function requestFailed(requestType, resourceType, response, details) {
  return {
    type: REQUEST_FAILED,
    requestType,
    resourceType,
    response,
    details,
  }
}

export const getRequestCache = (state) => state.requestCache

const getConfig = ({ config }) => config

export function* handleRequestsSaga() {
  yield [
    takeEvery(REQUEST_IF_NOT_CACHED, checkCache),
    takeEvery(GET_ALL, getAllSaga),
    takeEvery(GET, getSaga),
  ]
}

export function* checkCache(action) {
  const { requestType, resourceTypes, options } = action
  const cache = yield select(getRequestCache)
  for (let i = 0; i < resourceTypes.length; i++) {
    const resourceType = resourceTypes[i]
    const optionsObj = options[i]
    const cacheString = yield call(
      buildCacheString,
      requestType,
      resourceType,
      optionsObj
    )
    if (!cache[requestType] || !cache[requestType].includes(cacheString)) {
      yield call(submitRequest, requestType, resourceType, optionsObj)
    }
  }
}

export function* getSaga(action) {
  const { type, resourceType, query } = action
  const queryString = query ? `&${query}` : ''
  try {
    const envConfig = yield select(getConfig)
    const { GATEWAY_API_KEY } = envConfig
    const url = `${envConfig[resourceType]}?key=${GATEWAY_API_KEY}${queryString}`
    const response = yield call(request, url)
    yield put(requestSucceeded(type, resourceType, response, { query }))
  } catch (e) {
    console.error(e)
    yield put(requestFailed(type, resourceType, e, { query }))
  }
}

export function* getAllSaga(action) {
  const { type, resourceType, query } = action
  const queryString = query ? `&${query}` : ''
  try {
    const envConfig = yield select(getConfig)
    const { GATEWAY_API_KEY } = envConfig
    const url = `${envConfig[resourceType]}?key=${GATEWAY_API_KEY}${queryString}`
    const response = yield call(request, url)
    yield put(requestSucceeded(type, resourceType, response, { query }))
  } catch (e) {
    console.error(e)
    yield put(requestFailed(type, resourceType, e, { query }))
  }
}

export function* submitRequest(requestType, resourceType, options = {}) {
  const { id, query } = options
  switch (requestType) {
    case GET:
      yield put(_get(resourceType, id, query))
      break
    case GET_ALL:
      yield put(_getAll(resourceType, query))
      break
    default:
      return
  }
}

export function* authenticateRequest() {
  const user = yield select(getUser)
  return user ? { Authorization: user.accessToken } : {}
}
export const sagas = [handleRequestsSaga]
