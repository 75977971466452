import { all, fork } from 'redux-saga/effects'
import items, { sagas as itemsSagas } from './items'
import images, { sagas as imagesSagas } from './images'
import requestInProgress from './requestInProgress'
import config, { sagas as configSaga } from './config'
import user from './user'

const states = {
  config,
  items,
  images,
  user,
  requestInProgress,
}

export default states

const allSagas = [...configSaga, ...itemsSagas, ...imagesSagas]

export function* rootSaga() {
  yield all(allSagas.map((saga) => fork(saga)))
}
