import { HORIZONTAL, VERTICAL } from './constants'

export const getNest = (item) => {
  if (item) {
    if (item.vertical_nest != null && item.vertical_nest !== 0) {
      return { direction: VERTICAL, value: item.vertical_nest }
    } else if (item.horizontal_nest != null && item.horizontal_nest !== 0) {
      return { direction: HORIZONTAL, value: item.horizontal_nest }
    } else {
      return { direction: null, value: '' }
    }
  } else {
    return { direction: null, value: '' }
  }
}

export const setLatestNest = (nest) => {
  if (nest.direction === null) {
    return { vertical_nest: null, horizontal_nest: null }
  } else if (nest.direction === VERTICAL) {
    return {
      vertical_nest: nest.value ? parseFloat(nest.value) : null,
      horizontal_nest: null,
    }
  } else if (nest.direction === HORIZONTAL) {
    return {
      vertical_nest: null,
      horizontal_nest: nest.value ? parseFloat(nest.value) : null,
    }
  }
}

export const getPegholes = (item) => {
  if (item) {
    return {
      dpci: item.product_id,
      first: {
        left: getOffsetValuePeghole(item.peg_1_offset_left),
        down: getOffsetValuePeghole(item.peg_1_offset_down),
      },
      second: {
        left: getOffsetValuePeghole(item.peg_2_offset_left),
        down: getOffsetValuePeghole(item.peg_2_offset_down),
      },
    }
  }
  return {
    first: { left: '', down: '' },
    second: { left: '', down: '' },
  }
}

const getOffsetValuePeghole = (offset) => {
  if (offset == null) {
    return ''
  }
  return offset
}

export const getMock = (item) => {
  if (item) {
    return {
      mock_dimension: item.mock_dimension,
      mock_image: item.mock_image,
    }
  }
  return {
    mock_dimension: item.mock_dimension,
    mock_image: item.mock_image,
  }
}
