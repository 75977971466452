/* eslint-disable prettier/prettier */
import { Component } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { ModuleRegistry } from '@ag-grid-community/core'
import '@ag-grid-community/core/dist/styles/ag-grid.css'
import '@ag-grid-community/core/dist/styles/ag-theme-material.css'
import { DownloadGridItem, TableWrapper } from './styled'
import 'styled/index.css'
import DownloadBar from '../DownloadBar'
import { Container, GridItem, GridContainer } from 'canvas-ui-react'
import { LicenseManager } from '@ag-grid-enterprise/core'
import { connect } from 'react-redux'
import { frameworkComponents } from './frameworkComponents'

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  MenuModule,
  ExcelExportModule,
])

class Table extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modules: [MenuModule, ClientSideRowModelModule, ExcelExportModule],
      frameworkComponents: frameworkComponents,
      downloadBarVisibility: false,
      numberofRowsSelected: 0,
      tableExpand: true,
    }
  }
  showActionMenu = (params) => {
    const rowIndex = params.rowIndex
    const rowNode = params.node
    const column = params.column
    const mouseEvent = params.event
    const value = params.value
    const cell =
      params.api.rowRenderer.rowCompsByIndex[rowIndex].getRenderedCellForColumn(
        column
      )
    cell.beans.contextMenuFactory.showMenu(rowNode, column, value, mouseEvent)
  }
  onGridReady = (params) => {
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi

    if (this.props.getGridApi) {
      this.props.getGridApi(params.api)
    }
  }
  onSelectionChanged = () => {
    if (this.gridApi) {
      const numberofRowsSelected = this.gridApi.getSelectedRows().length
      if (numberofRowsSelected > 0) {
        this.setState({
          downloadBarVisibility: true,
          numberofRowsSelected: numberofRowsSelected,
          tableExpand: false,
        })
      } else {
        this.setState({
          downloadBarVisibility: false,
          numberofRowsSelected: numberofRowsSelected,
          tableExpand: true,
        })
      }
    }
  }
  render() {
    const {
      getContextMenuItems,
      columnDefs,
      downloadEnabled,
      config,
      tableName,
    } = this.props
    const { tableExpand, downloadBarVisibility, numberofRowsSelected } =
      this.state

    LicenseManager.setLicenseKey(config.AG_GRID_LICENSE_KEY)

    const columnDefinition = getContextMenuItems
      ? [
          ...columnDefs,
          {
            headerName: '',
            cellRenderer: 'actionsRenderer',
            colId: 'actions',
            onCellClicked: this.showActionMenu,
            maxWidth: 50,
            pinned: 'right',
          },
        ]
      : columnDefs

    return (
      <div>
        <Container padding="none">
          <GridContainer direction="column" spacing="none">
            <GridItem>
              <TableWrapper
                className={`ag-theme-material ${tableName}`}
                tableExpand={tableExpand}
              >
                <AgGridReact
                  {...this.props}
                  columnDefs={columnDefinition}
                  modules={this.state.modules}
                  rowSelection="multiple"
                  suppressRowClickSelection={true}
                  suppressContextMenu={true}
                  onGridReady={this.onGridReady}
                  //domLayout="autoHeight"
                  headerHeight={38}
                  rowHeight={70}
                  getContextMenuItems={getContextMenuItems}
                  frameworkComponents={this.state.frameworkComponents}
                  onSelectionChanged={this.onSelectionChanged.bind(this)}
                  onRowDataChanged={this.onSelectionChanged.bind(this)}
                  enableCellTextSelection={true}
                  ensureDomOrder={true}
                />
              </TableWrapper>
            </GridItem>
            {downloadEnabled && (
              <DownloadGridItem>
                <DownloadBar
                  visible={downloadBarVisibility}
                  numberofRowsSelected={numberofRowsSelected}
                  gridApi={this.gridApi}
                />
              </DownloadGridItem>
            )}
          </GridContainer>
        </Container>
      </div>
    )
  }
}
export default connect(({ config }) => ({ config }))(Table)
