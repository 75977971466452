import { Button, GridItem, Modal } from 'canvas-ui-react'
import logo from 'img/hexagon.svg'
import {
  Logo,
  StyledButton,
  WelcomeCaption,
  StyledGridContainer,
} from './styled'
import { useAuth } from '@praxis/component-auth'
import { connect } from 'react-redux'

const LoginModal = ({ config }) => {
  const auth = useAuth()
  return (
    <Modal closeButton={false} isVisible={true}>
      <StyledGridContainer direction="column" spacing="dense" align="center">
        <GridItem>
          <Logo src={logo} />
        </GridItem>
        <GridItem>
          <WelcomeCaption>Welcome</WelcomeCaption>
        </GridItem>
        <GridItem>
          <StyledButton raised fullWidth onClick={() => auth.login()}>
            {' '}
            LOGIN{' '}
          </StyledButton>
        </GridItem>
        {!config.IS_POL && (
          <GridItem>
            <a href={config.REQUEST_ACCESS_LINK}>
              <Button>REQUEST ACCESS</Button>
            </a>
          </GridItem>
        )}
      </StyledGridContainer>
    </Modal>
  )
}

export default connect(({ config }) => ({ config }))(LoginModal)
