import { Container, Divider, GridContainer, GridItem } from 'canvas-ui-react'
import { DialogHeading, DpciList, StyledDialog } from './styled'

export default function ErrorModal({ visible, list, showDialog, heading }) {
  return (
    <div>
      <StyledDialog
        isVisible={visible}
        onRefuseDialog={() => {
          showDialog(false)
        }}
        approveButtonText="Ok"
        onApproveDialog={() => {
          showDialog(false)
        }}
        className="items-not-found-dialog"
      >
        <Container>
          <GridContainer direction="column">
            <GridItem>
              <DialogHeading className="items-not-found-heading">
                {heading}
              </DialogHeading>
            </GridItem>
            <GridItem>
              <Divider />
            </GridItem>
            <GridItem>
              <DpciList className="dpcis-not-found">
                {list && list.join(' ')}
              </DpciList>
            </GridItem>
          </GridContainer>
        </Container>
      </StyledDialog>
    </div>
  )
}
