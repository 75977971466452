export const eventsMap = {
  SEARCH: {
    category: 'Search',
    action: 'Search DPCIs',
  },
  DOWNLOAD: {
    IMAGES: {
      category: 'Download',
      action: 'Download Images',
    },
    DIMENSIONS: {
      category: 'Download',
      action: 'Download Dimensions',
    },
  },
}
