import styled from 'styled-components'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import { Button, Container } from 'canvas-ui-react'

export const Animation = styled(CSSTransitionGroup)`
  ${({ transitionName }) => `.${transitionName}-enter`} {
    bottom: -100px;
  }

  ${({ transitionName }) => `.${transitionName}-leave`} {
    bottom: 12px;
  }
  ${({ transitionName }) =>
    `.${transitionName}-enter.${transitionName}-enter-active`} {
    bottom: 12px;
    transition: bottom 200ms linear;
  }
  ${({ transitionName }) =>
    `.${transitionName}-leave.${transitionName}-leave-active`} {
    bottom: -100px;
    transition: bottom 200ms linear;
  }
`
export const FixedDownloadBar = styled.div`
  position: fixed;
  bottom: 12px;
  right: 0%;
  left: 5%;
  background-color: #f7f7f7;
  box-shadow: 0 -5px 15px -10px #000000;
  height: 80px;
`
export const StyledButton = styled(Button)`
  margin-right: 20px;
`
export const StyledContainer = styled(Container)`
  margin-left: 20px;
`
