import { GridContainer, GridItem } from 'canvas-ui-react'
import { useState } from 'react'
import {
  Animation,
  FixedDownloadBar,
  StyledButton,
  StyledContainer,
} from './styled'
import DownloadModal from '../Modals/DownloadModal'

export default function DownloadBar({
  visible,
  numberofRowsSelected,
  gridApi,
}) {
  const [downloadModalOpen, setDownloadModalOpen] = useState(false)

  const openDownloadModal = () => {
    setDownloadModalOpen(true)
  }
  const closeDownloadModal = () => {
    setDownloadModalOpen(false)
  }
  var Component = visible ? (
    <FixedDownloadBar className="the-downloadbar" key="bar">
      <StyledContainer padding="expanded">
        <GridContainer justify="space-between" align="center" spacing="dense">
          <GridItem className="number-selected-rows">
            {numberofRowsSelected} item(s) selected
          </GridItem>
          <GridItem>
            <StyledButton
              raised
              onClick={() => {
                openDownloadModal()
              }}
              className="download-button"
            >
              DOWNLOAD
            </StyledButton>
          </GridItem>
        </GridContainer>
      </StyledContainer>
    </FixedDownloadBar>
  ) : (
    <div />
  )

  return (
    <div>
      <DownloadModal
        visible={downloadModalOpen}
        closeFunction={closeDownloadModal}
        numberofRowsSelected={numberofRowsSelected}
        gridApi={gridApi}
      />
      <Animation
        transitionName="downloadbar"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}
      >
        {Component}
      </Animation>
    </div>
  )
}
