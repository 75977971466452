export const removeDuplicates = (arr) => {
  return [...new Set(arr)]
}

export const intersection = (source, intersection) => {
  const intersectionSet = new Set(intersection)
  return source.filter((x) => intersectionSet.has(x))
}

export const union = (attributes, union) => {
  return attributes.concat(union)
}

export const difference = (source, comparator) => {
  const sourceSet = new Set(source)
  const comparatorSet = new Set(comparator)
  const difference = source.filter((x) => !comparatorSet.has(x))
  return difference.concat(comparator.filter((x) => !sourceSet.has(x)))
}

export const multiArrayIntersection = (arrays) => {
  if (!arrays.length) return
  const result = arrays.reduce((accumulator, value) =>
    value.filter(Set.prototype.has, new Set(accumulator))
  )
  return result
}

export const doesElementExist = (arr1, arr2) =>
  arr1.some((e1) => arr2.includes(e1))

export const multiArrayUnion = (arrays) => {
  if (!arrays.length) return
  return [].concat(...arrays)
}
