/* eslint-disable array-callback-return */
export const GetValueByKeyInNestedObject = (obj, target) =>
  target in obj
    ? obj[target]
    : Object.values(obj).reduce((acc, val) => {
        if (acc !== undefined) return acc
        if (typeof val === 'object') {
          return GetValueByKeyInNestedObject(val, target)
        }
      }, undefined)

export const shallowCompareObjectKeysForEquality = (obj1, obj2) =>
  Object.keys(obj1).length !== Object.keys(obj2).length
    ? false
    : Object.keys(obj1).every((key) => key in obj2)
