import { CASE, HORIZONTAL, LOOSE, VERTICAL } from './constants'
import { toArray, toObject } from './reshape'
const UNIT = 'UNIT'
const ALTERNATE = 'ALTERNATE'
const TRAY = 'TRAY'
const DISPLAY = 'DISPLAY'

export const getItemsForSearchTable = (items) => {
  let result = []
  items &&
    toArray(items).forEach((itemImage) => {
      const dimensionsObject =
        itemImage.dimensions && toObject(itemImage.dimensions, 'style')
      result.push({
        dpci: itemImage.product_id,
        image: itemImage.image,
        image_count: itemImage.product_image_paths.length,
        unit: getItemDimensionByStyle(itemImage, UNIT),
        alternate: getItemDimensionByStyle(itemImage, ALTERNATE),
        tray: getItemDimensionByStyle(itemImage, TRAY),
        display: getItemDimensionByStyle(itemImage, DISPLAY),
        tray_unit: getTrayUnitDimensions(itemImage),
        optional_description: itemImage.product_description,
        product_image_paths: itemImage.product_image_paths,
        peghole_one: getPegHole(
          itemImage.peg_1_offset_left,
          itemImage.peg_1_offset_down
        ),
        peghole_two: getPegHole(
          itemImage.peg_2_offset_left,
          itemImage.peg_2_offset_down
        ),
        last_modified: itemImage.item_modified_date,
        dimensions_updated_date: itemImage.dimensions_updated_date,
        unit_height: dimensionsObject && dimensionsObject.UNIT.height,
        unit_width: dimensionsObject && dimensionsObject.UNIT.width,
        unit_depth: dimensionsObject && dimensionsObject.UNIT.depth,
        alternate_height: dimensionsObject && dimensionsObject.ALTERNATE.height,
        alternate_width: dimensionsObject && dimensionsObject.ALTERNATE.width,
        alternate_depth: dimensionsObject && dimensionsObject.ALTERNATE.depth,
        tray_height: dimensionsObject && dimensionsObject.TRAY.height,
        tray_width: dimensionsObject && dimensionsObject.TRAY.width,
        tray_depth: dimensionsObject && dimensionsObject.TRAY.depth,
        display_height: dimensionsObject && dimensionsObject.DISPLAY.height,
        display_width: dimensionsObject && dimensionsObject.DISPLAY.width,
        display_depth: dimensionsObject && dimensionsObject.DISPLAY.depth,
        peg_1_offset_left: itemImage.peg_1_offset_left,
        peg_1_offset_down: itemImage.peg_1_offset_down,
        peg_2_offset_left: itemImage.peg_2_offset_left,
        peg_2_offset_down: itemImage.peg_2_offset_down,
        horizontal_nest: itemImage.horizontal_nest,
        vertical_nest: itemImage.vertical_nest,
        mock_image: itemImage.mock_image,
        mock_dimension: itemImage.mock_dimension,
        tray_high: itemImage.tray_high,
        tray_wide: itemImage.tray_wide,
        tray_deep: itemImage.tray_deep,
      })
    })
  return result
}

const getItemDimensionByStyle = (item, style) => {
  let result = 'Not Found'
  style &&
    item &&
    item.dimensions &&
    item.dimensions.forEach((dimension) => {
      if (dimension.style === style) {
        result = ''
          .concat(dimension.height)
          .concat('"Hx')
          .concat(dimension.width)
          .concat('"Wx')
          .concat(dimension.depth)
          .concat('"D')
      }
    })
  return result
}
const getTrayUnitDimensions = (item) => {
  let result = 'Not Found'
  if (item && (item.tray_high || item.tray_wide || item.deep)) {
    result = ''
      .concat(item.tray_high)
      .concat('"Hx')
      .concat(item.tray_wide)
      .concat('"Wx')
      .concat(item.tray_deep)
      .concat('"D')
  }
  return result
}

export const combineItemsWithImage = (imagePreviews, response) => {
  let itemWithImages = []
  const responseArray = toArray(response)
  if (!imagePreviews || !imagePreviews.length) {
    return responseArray
  }
  const imagePreviewsObject = toObject(imagePreviews, 'dpci')
  responseArray.forEach((singleResponse) => {
    const dpci = singleResponse.product_id
    const itemForDpci = response[dpci]
    const itemWithImage = {
      ...itemForDpci,
      image:
        imagePreviewsObject[dpci] &&
        URL.createObjectURL(imagePreviewsObject[dpci].imageBlob),
    }
    itemWithImages.push(itemWithImage)
  })
  return itemWithImages
}
export const validDpci = (dpci) => {
  let validExp = /^(\d){8,9}$/
  return validExp.test(dpci)
}
export const parseSearchDpci = (dpcis) => {
  dpcis = dpcis.replace(/-/g, '')
  dpcis = dpcis.replace(/\n/g, ' ')
  dpcis = dpcis.replace(/[, ]+/g, ' ').trim()
  dpcis = dpcis.split(' ')
  return dpcis
}

const getPegHole = (peg_1_offset_left, peg_1_offset_down) => {
  if (peg_1_offset_left && peg_1_offset_down) {
    return 'Offset(1) '
      .concat(peg_1_offset_left)
      .concat('" X ')
      .concat(peg_1_offset_down)
      .concat('"')
  } else if (peg_1_offset_left) {
    return 'Offset(1) Left '.concat(peg_1_offset_left).concat('"')
  } else if (peg_1_offset_down) {
    return 'Offset(1) Down '.concat(peg_1_offset_down).concat('"')
  } else {
    return 'Not Found'
  }
}

export const getRowsForEditTable = (item) => {
  let result = []
  item &&
    item.dimensions &&
    item.dimensions
      .filter(
        (dimension) => dimension.style !== CASE && dimension.style !== LOOSE
      )
      .forEach((dimension) => {
        let row = {
          style: dimension.style,
          height: dimension.height,
          width: dimension.width,
          depth: dimension.depth,
        }
        result.push(row)
      })
  return result
}

export const formatDpci = (dpci) => {
  //splitting into department etc.
  return (
    dpci.substring(0, 3) + '-' + dpci.substring(3, 5) + '-' + dpci.substring(5)
  )
}

export const getUpdatedNest = (nest) => {
  if (nest.direction === null) {
    return { vertical_nest: null, horizontal_nest: null }
  } else if (nest.direction === VERTICAL) {
    return {
      vertical_nest: nest.value ? parseFloat(nest.value) : null,
      horizontal_nest: null,
    }
  } else if (nest.direction === HORIZONTAL) {
    return {
      vertical_nest: null,
      horizontal_nest: nest.value ? parseFloat(nest.value) : null,
    }
  }
}

export const getUpdatedPegholes = (pegholes) => {
  return {
    peg_1_offset_left: getValidOffset(pegholes.first.left),
    peg_1_offset_down: getValidOffset(pegholes.first.down),
    peg_2_offset_left: getValidOffset(pegholes.second.left),
    peg_2_offset_down: getValidOffset(pegholes.second.down),
  }
}

const getValidOffset = (value) => {
  if (value === '') {
    return null
  } else {
    return parseFloat(value)
  }
}
