import loading from 'img/loading4.gif'
import Backdrop from '@material-ui/core/Backdrop'
import { makeStyles } from '@material-ui/core/styles'
import { doesElementExist } from 'util/arrayUtils'
import { connect } from 'react-redux'
import { ITEM } from 'ducks/items'
import { IMAGE } from 'ducks/images'
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))
const LoadingModal = ({ requestInProgress }) => {
  const classes = useStyles()
  const searchInProgress = () => {
    return (
      doesElementExist([ITEM], requestInProgress) ||
      doesElementExist([IMAGE], requestInProgress)
    )
  }
  return (
    <Backdrop className={classes.backdrop} open={searchInProgress()}>
      <img style={{ width: '15%' }} src={loading} alt="loading" />
    </Backdrop>
  )
}
export default connect(({ requestInProgress }) => ({ requestInProgress }))(
  LoadingModal
)
