import { createSelector } from 'reselect'

export const SET_USER = 'user/SET_USER'
export const PERMISSION_DENIED = 'user/DENIED'
export const PERMISSION_READ = 'user/READ'
export const PERMISSION_WRITE = 'user/WRITE'

export function setUser(user) {
  return {
    type: SET_USER,
    user,
  }
}
export default function reducer(state = {}, action = {}) {
  const { type, user = {} } = action
  switch (type) {
    case SET_USER:
      return user
    default:
      return state
  }
}

export const getUser = ({ user }) => user
const getConfig = ({ config }) => config

const checkPermissions = (userGroups, groups) =>
  userGroups.some((userGroup) => groups.includes(userGroup))

export const getPermissions = createSelector(
  [getUser, getConfig],
  (user, config) => {
    let permissions = []
    const { AD_GROUP, READ_AD_GROUP } = config
    const writeGroups = [AD_GROUP]
    const readGroups = [READ_AD_GROUP]
    if (user && Object.keys(user).length) {
      const userGroups = user.memberOf
      if (checkPermissions(userGroups, writeGroups)) {
        permissions.push(PERMISSION_WRITE)
      }
      if (
        !permissions.includes(PERMISSION_WRITE) &&
        checkPermissions(userGroups, readGroups)
      ) {
        permissions.push(PERMISSION_READ)
      }
    }
    if (!permissions.length) {
      permissions.push(PERMISSION_DENIED)
    }
    return permissions
  }
)
