import { OptionalDescription } from 'components/Table/styled'
import bear from 'img/bear.svg'
import { formatDpci } from './format'

//Cell renderer for DPCI column to add optional description
//This will be removed later when image column is ready
export const OptionalDescriptionAdder = ({ value }) => {
  //value[0] = dpci, value[1] = description
  const dpci = value[0]
  const description = value[1]
  return (
    <div>
      {formatDpci(dpci)}
      <OptionalDescription>{description}</OptionalDescription>
    </div>
  )
}

export const getDownloadDimensionsValue = () => {
  return {
    fileName: 'product Dimensions ' + new Date(),
    customHeader: [
      [
        {
          styleId: 'bigHeader',
          data: {
            type: 'String',
            value: 'DPCI',
          },
        },
        {
          styleId: 'bigHeader',
          data: {
            type: 'String',
            value: 'UNIT (inches)',
          },
          mergeAcross: 2,
        },
        {
          styleId: 'bigHeader',
          data: {
            type: 'String',
            value: 'ALTERNATE (inches)',
          },
          mergeAcross: 2,
        },
        {
          styleId: 'bigHeader',
          data: {
            type: 'String',
            value: 'DISPLAY (inches)',
          },
          mergeAcross: 2,
        },
        {
          styleId: 'bigHeader',
          data: {
            type: 'String',
            value: 'TRAY (inches)',
          },
          mergeAcross: 5,
        },
        {
          styleId: 'bigHeader',
          data: {
            type: 'String',
            value: 'Peghole 1 (inches)',
          },
          mergeAcross: 1,
        },
        {
          styleId: 'bigHeader',
          data: {
            type: 'String',
            value: 'Peghole 2(inches)',
          },
          mergeAcross: 1,
        },
        {
          styleId: 'bigHeader',
          data: {
            type: 'String',
            value: 'Nest',
          },
          mergeAcross: 1,
        },
        {
          styleId: 'bigHeader',
          data: {
            type: 'String',
            value: 'Mock Image Dimensions',
          },
          mergeAcross: 1,
        },
      ],
    ],
    columnKeys: [
      'dpci_duplicate',
      'optional_description',
      'unit_height',
      'unit_width',
      'unit_depth',
      'alternate_height',
      'alternate_width',
      'alternate_depth',
      'display_height',
      'display_width',
      'display_depth',
      'tray_height',
      'tray_width',
      'tray_depth',
      'tray_high',
      'tray_wide',
      'tray_deep',
      'peg_1_offset_left',
      'peg_1_offset_down',
      'peg_2_offset_left',
      'peg_2_offset_down',
      'nest_value',
      'nest_direction',
      'mock_image',
      'mock_dimension',
    ],
    onlySelected: true,
  }
}
export const NestRenderer = ({ value }) => {
  //return Not Found if both vertical, horizontal are 0 or null
  let finalNest = 'Not Found'
  if (value.vertical_nest) {
    finalNest = ''.concat('Vertical ', value.vertical_nest, '" ')
  } else if (value.horizontal_nest) {
    finalNest = ''.concat('Horizontal ', value.horizontal_nest, '" ')
  }
  return finalNest
}

export const searchTableImageRenderer = ({ value }) =>
  value[0]
    ? `    <div class=ag-image-parent-container>
      <img class=ag-search-image-container src=${value[0]} />
      <div class=ag-search-image-count-container>
        ${value[1]} images
      </div>
    </div>
`
    : `    <div class=ag-image-parent-container>
      <img class=ag-search-image-container src=${bear} />
      <div class=ag-search-image-count-container>
        ${value[1]} images
      </div>
    </div>
`
