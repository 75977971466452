import { Container, Form, GridContainer } from 'canvas-ui-react'
import { mock_options } from 'util/constants'
import { MockFieldGridItem } from './styled'

export default function Mock({
  mockItem,
  setMockItem,
  dpci,
  setEditingStarted,
}) {
  return (
    <div>
      <Container>
        <GridContainer direction="row">
          <MockFieldGridItem>
            <Form.Label> Mock Images </Form.Label>
            <Form.Select
              options={mock_options}
              value={mockItem.mock_image}
              onChange={(event) => {
                setEditingStarted(true)
                setMockItem({
                  ...mockItem,
                  mock_image: mock_options[event.target.value].value,
                })
              }}
            />
          </MockFieldGridItem>
          <MockFieldGridItem>
            <Form.Label> Mock Dimensions</Form.Label>
            <Form.Select
              options={mock_options}
              value={mockItem.mock_dimension}
              onChange={(event) => {
                setEditingStarted(true)
                setMockItem({
                  ...mockItem,
                  mock_dimension: mock_options[event.target.value].value,
                })
              }}
            />
          </MockFieldGridItem>
        </GridContainer>
      </Container>
    </div>
  )
}
