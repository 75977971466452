export const toArray = (input) => Object.values(input)

export const toObject = (input, key = 'id') => {
  // converts array of objects in to Object with specified Key
  return input.reduce((l, r) => ({ ...l, [r[key]]: r }), {})
}

export const convertArrayToObjectWithKey = (array, object_key) => {
  // converts array of objects in to Object with specified key and groups values as an array of Objects.
  let result = {}
  array.forEach((record) => {
    const key = record[object_key]
    result = {
      ...result,
      [key]: [...(key in result ? result[key] : []), record],
    }
  })

  return result
}
