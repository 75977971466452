import Home from 'pages/Home'

const routes = {
  home: {
    module_name: 'PPID',
    path: '/',
    component: Home,
    protected: true,
  },
}

export default routes
