import { Button, GridContainer } from 'canvas-ui-react'
import styled from 'styled-components'
import img from 'img/welcomebackground.jpg'

export const Logo = styled.img`
  width: 100px;
`
export const WelcomeCaption = styled.div`
  font-size: 60px;
  font-weight: bold;
`
export const StyledButton = styled(Button)`
  min-width: 220px;
`
export const StyledGridContainer = styled(GridContainer)`
  padding: 100px;
  // background-image: url(${img});
  background-size: cover;
`
