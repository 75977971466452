import { Button, GridItem } from 'canvas-ui-react'
import styled from 'styled-components'

export const Dpci = styled.p`
  font-size: 30px;
  font-weight: bold;
`
export const NestContainerGridItem = styled(GridItem)`
  margin-top: 15px;
`
export const NestTitle = styled.p`
  color: gray;
`
export const NestGridItem = styled(GridItem)`
  width: 140px;
`
export const NestValueGridItem = styled(GridItem)`
  width: 90px;
  padding-left: 6px;
`
export const BatchActionsGridItem = styled(GridItem)`
  margin-left: 12px;
`
export const DimensionEditor = styled.div`
  padding-top: 8px;
`
export const EditTable = styled.div`
  width: 100%;
`
export const HeaderGridItem = styled(GridItem)`
  background-color: #ececec;
`
export const TableGridItem = styled(GridItem)``

export const PegholeGridItem = styled(GridItem)`
  padding-left: 10px;
`
export const PegholeItem = styled(GridItem)`
  width: 150px;
`
export const StyledButton = styled(Button)`
  background-color: white;
  border: 0px;
`
export const ActionGridItem = styled(GridItem)`
  z-index: 10;
  background-color: white;
  padding: 20px;
  width: 100%;
  position: fixed;
  bottom: 0px;
  border-top: 1px solid rgb(221, 217, 217);
`
export const Main = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 100px);
`
export const MockGridItem = styled(GridItem)`
  padding-right: 10px;
`

export const MockFieldGridItem = styled(GridItem)`
  width: 150px;
`
