/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import { Container, Heading } from 'canvas-ui-react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { setUser } from 'ducks/user'
import { useAuth } from '@praxis/component-auth'
import { Main } from './styled'
import SearchResults from 'containers/SearchResults'
import LoadingModal from 'components/Modals/LoadingModal'
import ErrorModal from 'components/Modals/ErrorModal'

const Home = ({ _setUser, items }) => {
  const [showError, setShowError] = useState(false)
  const [errorList, setErrorList] = useState([])
  const [errorHeading, setErrorHeading] = useState('')
  const auth = useAuth()
  useEffect(() => {
    if (auth.isAuthenticated()) {
      _setUser(auth.session.userInfo)
    }
  }, [])
  const showResults = () => {
    return items.showResults
  }
  return (
    <Container>
      <LoadingModal />
      <ErrorModal
        visible={showError}
        list={errorList}
        showDialog={setShowError}
        heading={errorHeading}
      />

      {showResults() ? (
        <div>
          <SearchResults
            showDialog={setShowError}
            setErrorList={setErrorList}
            setErrorHeading={setErrorHeading}
          />
        </div>
      ) : (
        <Main>
          <Heading size="2">Get Started</Heading>
          <Heading size="2">Search or upload by DPCI</Heading>
        </Main>
      )}
    </Container>
  )
}

export default connect(
  ({ items, requestInProgress }) => ({ items, requestInProgress }),
  (dispatch) => ({
    _setUser: (user) => dispatch(setUser(user)),
  })
)(Home)
