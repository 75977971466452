import {
  REQUEST_SUCCEEDED,
  requestFailed,
  requestSucceeded,
  REQUEST_FAILED,
} from './requests'
import { toObject, toArray } from 'util/reshape'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import request, { downloadFile, parseBlob } from 'util/request'
import { getUser } from './user'
import { combineItemsWithImage } from 'util/format'
import Notify, { TYPE } from 'components/Notify'

export const initialState = {
  data: {},
  error: { DPCIS_NOT_FOUND: [], DPCIS_WITHOUT_IMAGES: [] },
  showResults: false,
}
export const GET_ITEMS = 'requests/GET_ITEMS'
export const GET_ITEMS_IMAGE = 'requests/GET_ITEMS_IMAGE'
export const DPCIS_NOT_FOUND = 'DPCIS_NOT_FOUND'
export const DPCIS_WITHOUT_IMAGES = 'DPCIS_WITHOUT_IMAGES'
export const PUT_ITEM = 'PUT_ITEM'
const PRODUCT_ID = 'product_id'
export const ITEM = 'ITEM'

const getConfig = ({ config }) => config
const getItemsState = ({ items }) => items

export default function reducer(state = initialState, action) {
  const { type, resourceType, requestType, response, details } = action
  switch (type) {
    case REQUEST_SUCCEEDED:
      if (
        resourceType === ITEM &&
        (requestType === GET_ITEMS || requestType === GET_ITEMS_IMAGE)
      ) {
        return {
          data: toObject(response.data, PRODUCT_ID),
          error: {
            DPCIS_NOT_FOUND: response.error.DPCIS_NOT_FOUND,
            DPCIS_WITHOUT_IMAGES: response.error.DPCIS_WITHOUT_IMAGES,
          },
          showResults: true,
        }
      } else if (resourceType === ITEM && requestType === PUT_ITEM) {
        return {
          data: { ...state.data, ...toObject([response], PRODUCT_ID) },
          error: state.error,
          showResults: true,
        }
      } else {
        return state
      }
    case REQUEST_FAILED:
      if (resourceType === ITEM && requestType === GET_ITEMS_IMAGE) {
        return {
          data: {},
          error: {
            DPCIS_NOT_FOUND: details.error.DPCIS_NOT_FOUND,
            DPCIS_WITHOUT_IMAGES: [],
          },
          showResults: true,
        }
      } else {
        return state
      }
    // eslint-disable-next-line no-fallthrough
    default:
      return state
  }
}

export function getItems(query) {
  return {
    type: GET_ITEMS,
    resourceType: ITEM,
    query,
  }
}

export function getItemsImage(query) {
  return {
    type: GET_ITEMS_IMAGE,
    resourceType: ITEM,
    query,
  }
}

export function putItem(dpci, updatedItemDetails) {
  return {
    type: PUT_ITEM,
    resourceType: ITEM,
    dpci,
    updatedItemDetails,
  }
}

export function* itemsWatcherSaga() {
  yield takeLatest(GET_ITEMS, getItemsWorker)
  yield takeLatest(GET_ITEMS_IMAGE, getItemsImageWorker)
  yield takeLatest(PUT_ITEM, putItemWorker)
}

export function* putItemWorker(action) {
  const { dpci, updatedItemDetails, type, resourceType } = action

  try {
    const items = yield select(getItemsState)
    const envConfig = yield select(getConfig)
    const { GATEWAY_API_KEY } = envConfig
    const url = `${envConfig.POG_IMAGE_SVC}/products/${dpci}?key=${GATEWAY_API_KEY}`

    const originalItemDetails = items.data[dpci]

    const latestItemDetails = { ...originalItemDetails, ...updatedItemDetails }

    //backing up image
    const productImage = latestItemDetails.image

    //deleting from the put request body
    delete latestItemDetails.image

    const options = {
      method: 'PUT',
      headers: {
        ...(yield call(authenticateRequest)),
        'Content-Type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify(latestItemDetails),
    }

    const response = yield call(request, url, options)

    const responseWithImage = { ...response, image: productImage }

    yield put(requestSucceeded(type, resourceType, responseWithImage))

    Notify({
      message: `Update Successful`,
      type: TYPE.SUCCESS,
    })
  } catch (e) {
    Notify({
      message: `Update Failed`,
      type: TYPE.ERROR,
    })
  }
}

export function* getItemsWorker(action) {
  const { type, resourceType, query } = action
  const queryString = query && query.join(',')
  if (!queryString) {
    return
  }
  try {
    const envConfig = yield select(getConfig)
    const { GATEWAY_API_KEY } = envConfig
    const options = {
      method: 'GET',
      headers: {
        ...(yield call(authenticateRequest)),
      },
    }
    const url = `${envConfig.POG_IMAGE_SVC}/products/${queryString}?key=${GATEWAY_API_KEY}`
    const response = yield call(request, url, options)
    yield put(requestSucceeded(type, resourceType, response, { query }))
  } catch (e) {
    console.error(e)
    yield put(requestFailed(type, resourceType, e, { query }))
  }
}

export function* getItemsImageWorker(action) {
  const { type, resourceType, query } = action
  const dpciList = query
  const queryString = query && query.join(',')
  if (!queryString) {
    yield put(
      requestFailed(type, resourceType, 'Invalid DPCIs', {
        error: query,
      })
    )
    return
  }
  try {
    const envConfig = yield select(getConfig)
    const { GATEWAY_API_KEY } = envConfig
    const options = {
      method: 'GET',
      headers: {
        ...(yield call(authenticateRequest)),
      },
    }
    const url = `${envConfig.POG_IMAGE_SVC}/products/${queryString}?key=${GATEWAY_API_KEY}`
    let response = yield call(request, url, options)
    response = toObject(response, 'product_id')

    const imageAvailableItems = toArray(response).filter(
      ({ product_image_paths }) => product_image_paths.length > 0
    )

    const imageCalls = imageAvailableItems.map(({ product_image_paths }) =>
      (function () {
        try {
          let fileName =
            product_image_paths &&
            product_image_paths.length &&
            product_image_paths[0].split('images\\')[1]
          let url = `${envConfig.POG_IMAGE_SVC}/images/${fileName}?key=${GATEWAY_API_KEY}`
          return call(downloadFile, url, options)
        } catch (e) {
          return e
        }
      })()
    )
    let imagePreviews = yield all(imageCalls)
    imagePreviews = imagePreviews.filter((image) => image.ok)

    imagePreviews = yield all(imagePreviews.map((image) => parseBlob(image)))

    const itemWithImages = combineItemsWithImage(imagePreviews, response)
    const availableDpci_list = itemWithImages.map((item) => item.product_id)
    const unavailableDpci_list = dpciList.filter(
      (dpci) => !availableDpci_list.includes(dpci)
    )
    const itemsWithoutImages = itemWithImages
      .filter((item) => item.product_image_paths.length < 1)
      .map((item) => item.product_id)

    yield put(
      requestSucceeded(
        type,
        resourceType,
        {
          data: itemWithImages,
          error: {
            DPCIS_NOT_FOUND: unavailableDpci_list,
            DPCIS_WITHOUT_IMAGES: itemsWithoutImages,
          },
        },
        { query }
      )
    )
  } catch (e) {
    console.error(e)
    yield put(
      requestFailed(type, resourceType, e, {
        error: { DPCIS_NOT_FOUND: query, DPCIS_WITHOUT_IMAGES: [] },
      })
    )
  }
}

export function* authenticateRequest() {
  const user = yield select(getUser)
  return user ? { Authorization: user.accessToken } : {}
}

export const sagas = [itemsWatcherSaga]
