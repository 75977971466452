//collects both dpci and opt. description to display in one cell
//to be removed later when opt. description is removed
import { searchTableImageRenderer } from 'util/columnDefHelper'

const dpciAndDescription = (params) => {
  return [params.data.dpci, params.data.optional_description]
}
const nestGetter = (params) => {
  return {
    vertical_nest: params.data.vertical_nest,
    horizontal_nest: params.data.horizontal_nest,
  }
}
const nest_ValueGetter = (params) => {
  if (params.data.vertical_nest) {
    return params.data.vertical_nest
  } else if (params.data.horizontal_nest) {
    return params.data.horizontal_nest
  } else {
    return
  }
}
const nest_DirectionGetter = (params) => {
  if (params.data.vertical_nest) {
    return 'Vertical'
  } else if (params.data.horizontal_nest) {
    return 'Horizontal'
  } else {
    return
  }
}
const mockImageGetter = (params) => {
  if (params.data.mock_image) {
    return 'Yes'
  }
  return
}
const mockDimensionGetter = (params) => {
  if (params.data.mock_dimension) {
    return 'Yes'
  }
  return
}

export const columnDefs = [
  {
    headerName: 'DPCI',
    field: 'dpci',
    width: 350,
    headerCheckboxSelection: 'true',
    valueGetter: dpciAndDescription,
    cellRenderer: 'optionalDescriptionAdder',
    checkboxSelection: true,
  },
  {
    headerName: 'Image',
    field: 'image',
    width: 190,
    cellRenderer: searchTableImageRenderer,
    valueGetter: ({ data: { image, image_count } }) => [image, image_count],
  },
  {
    headerName: 'More dimensions',
    headerClass: 'header',
    children: [
      {
        headerName: 'Unit(HxWxD)',
        field: 'unit',
        sortable: 'true',
        unSortIcon: 'true',
        width: 190,
      },
      {
        headerName: 'Alternate(HxWxD)',
        field: 'alternate',
        sortable: 'true',
        unSortIcon: 'true',
        columnGroupShow: 'open',
        width: 205,
      },
      {
        headerName: 'Display (HxWxD)',
        field: 'display',
        sortable: 'true',
        unSortIcon: 'true',
        columnGroupShow: 'open',
        width: 200,
      },
      {
        headerName: 'Tray (HxWxD)',
        field: 'tray',
        sortable: 'true',
        unSortIcon: 'true',
        columnGroupShow: 'open',
        width: 175,
      },
      {
        headerName: 'Tray Unit (HxWxD)',
        field: 'tray_unit',
        columnGroupShow: 'open',
        width: 190,
      },
    ],
  },
  {
    headerName: 'Peghole - One',
    field: 'peghole_one',
    sortable: 'true',
    unSortIcon: 'true',
    width: 190,
  },
  {
    headerName: 'Peghole - Two',
    field: 'peghole_two',
    sortable: 'true',
    unSortIcon: 'true',
    width: 190,
  },
  {
    headerName: 'Nest',
    field: 'nest',
    valueGetter: nestGetter,
    cellRenderer: 'nestRenderer',
    sortable: 'true',
    unSortIcon: 'true',
    width: 150,
  },
  {
    headerName: 'Dimensions Last Updated',
    field: 'dimensions_updated_date',
    sortable: 'true',
    unSortIcon: 'true',
    minWidth: 250,
    flex: 1,
  },
  //The below columns are used in creating dimensions excel file.
  {
    headerName: '',
    field: 'dpci',
    colId: 'dpci_duplicate',
    cellClass: 'textFormat',
    width: 100,
    hide: true,
  },
  {
    field: 'unit_height',
    headerName: 'Unit Height',
    width: 60,
    hide: true,
  },
  {
    field: 'unit_width',
    headerName: 'Unit Length/Width',
    width: 120,
    hide: true,
  },
  {
    field: 'unit_depth',
    headerName: 'Unit Depth',
    width: 80,
    hide: true,
  },

  {
    field: 'alternate_height',
    headerName: 'Alternate Height',
    width: 110,
    hide: true,
  },
  {
    field: 'alternate_width',
    headerName: 'Alternate Length/Width',
    width: 150,
    hide: true,
  },
  {
    field: 'alternate_depth',
    headerName: 'Alternate Depth',
    width: 100,
    hide: true,
  },

  {
    field: 'tray_height',
    headerName: 'Tray Height',
    width: 90,
    hide: true,
  },
  {
    field: 'tray_width',
    headerName: 'Tray Length/Width',
    width: 130,
    hide: true,
  },
  {
    field: 'tray_depth',
    headerName: 'Tray Depth',
    width: 100,
    hide: true,
  },
  {
    field: 'tray_high',
    headerName: 'Tray Units High',
    width: 100,
    hide: true,
  },
  {
    field: 'tray_wide',
    headerName: 'Tray Units Wide',
    width: 100,
    hide: true,
  },
  {
    field: 'tray_deep',
    headerName: 'Tray Units Deep',
    width: 100,
    hide: true,
  },
  {
    field: 'display_height',
    headerName: 'Display Height',
    width: 100,
    hide: true,
  },
  {
    field: 'display_width',
    headerName: 'Display Length/Width',
    width: 140,
    hide: true,
  },
  {
    field: 'display_depth',
    headerName: 'Display Depth',
    width: 100,
    hide: true,
  },

  {
    field: 'peg_1_offset_left',
    headerName: 'Offset',
    width: 50,
    hide: true,
  },
  {
    field: 'peg_1_offset_down',
    headerName: 'Down',
    width: 50,
    hide: true,
  },
  {
    field: 'peg_2_offset_left',
    headerName: 'Offset',
    width: 50,
    hide: true,
  },
  {
    field: 'peg_2_offset_down',
    headerName: 'Down',
    width: 50,
    hide: true,
  },
  {
    field: 'nest_value',
    headerName: 'Value(inches)',
    width: 100,
    valueGetter: nest_ValueGetter,
    hide: true,
  },
  {
    field: 'nest_direction',
    headerName: 'Direction',
    width: 100,
    valueGetter: nest_DirectionGetter,
    hide: true,
  },
  {
    field: 'mock_image',
    headerName: 'Mock Image',
    width: 80,
    valueGetter: mockImageGetter,
    hide: true,
  },
  {
    field: 'mock_dimension',
    headerName: 'Mock Dimension',
    width: 100,
    valueGetter: mockDimensionGetter,
    hide: true,
  },
]
export default columnDefs

export const defaultColDef = {
  suppressMenu: true,
  resizable: true,
}
