import { useState } from 'react'
import { faClipboard } from '@fortawesome/free-solid-svg-icons'
import {
  Heading,
  Form,
  IconGroup,
  Container,
  GridContainer,
  GridItem,
  Button,
} from 'canvas-ui-react'
import {
  SubHeading,
  TextArea,
  StyledIcon,
  StyledDialog,
  StyledGridItem,
} from './styled'

export default function CopyPasteModal({
  handleSearch,
  open,
  handleClickOpen,
  handleClickClose,
}) {
  const [searchString, setSearchString] = useState('')
  return (
    <div>
      <IconGroup>
        {/* have to display 'Copy & Paste List of DPCI' when hovering on icon */}
        <StyledIcon
          className="copy-paste-modal-button"
          icon={faClipboard}
          onClick={handleClickOpen}
        />
      </IconGroup>

      <StyledDialog
        onRefuseDialog={handleClickClose}
        isVisible={open}
        className="copy-paste-modal"
      >
        <Container>
          <GridContainer direction="column">
            <GridItem xs>
              <Heading className="copy-paste-main-heading">
                Copy & Paste List of DPCI(s)
              </Heading>
              <SubHeading>
                Type or paste DPCI below. Seperate DPCI with a space or comma.
              </SubHeading>
            </GridItem>
            <StyledGridItem>
              <TextArea>
                <Form.Textarea
                  id="demo_00"
                  onChange={(event) => {
                    setSearchString(event.target.value)
                  }}
                  value={searchString}
                  placeholder="Example : 000-00-0000 or 000000000"
                />
              </TextArea>
            </StyledGridItem>
          </GridContainer>

          <GridContainer direction="row-reverse" spacing="dense">
            <GridItem>
              <Button
                raised
                onClick={() => {
                  handleSearch(searchString)
                  setSearchString('')
                }}
                className="copypaste-search-button"
              >
                Search
              </Button>
            </GridItem>
            <GridItem>
              <Button
                outlined
                onClick={handleClickClose}
                className="copypaste-cancel-button"
              >
                Cancel
              </Button>
            </GridItem>
          </GridContainer>
        </Container>
      </StyledDialog>
    </div>
  )
}
