import {
  Button,
  Container,
  Divider,
  GridContainer,
  GridItem,
} from 'canvas-ui-react'
import { useState } from 'react'
import { connect } from 'react-redux'
import { getImages } from 'ducks/images'
import { getDownloadDimensionsValue } from 'util/columnDefHelper'
import {
  Caption,
  SubCaption,
  StyledFormCheckbox,
  StyledFormCheckboxJpg,
  StyledDialog,
  StyledGridContainer,
  DownloadButton,
} from './styled'
import { trackEvent } from 'components/Analytics/trackAnalytics'
import { eventsMap } from 'components/Analytics/eventsMap'

const DownloadModal = ({
  visible,
  closeFunction,
  numberofRowsSelected,
  gridApi,
  getImages,
}) => {
  const [DimensionDownload, setDimensionDownload] = useState(false)
  const [ImageDownload, setImageDownload] = useState(false)
  const [downloadAsJpg, setDownloadAsJpg] = useState(false)
  const handleDownload = () => {
    let itemsWithNoImages = []
    let imageLocations = []
    if (DimensionDownload) {
      let params = getDownloadDimensionsValue()
      gridApi.exportDataAsExcel(params)
      trackEvent({
        ...eventsMap.DOWNLOAD.DIMENSIONS,
        value: numberofRowsSelected,
      })
    }
    if (ImageDownload) {
      gridApi.getSelectedRows().forEach((item) => {
        if (item.product_image_paths.length > 0) {
          item.product_image_paths.forEach((path) => {
            imageLocations.push(path.split('images\\')[1])
          })
        } else {
          itemsWithNoImages.push(item.dpci)
        }
      })

      if (imageLocations.length > 0) {
        getImages(imageLocations, downloadAsJpg)
        trackEvent({
          ...eventsMap.DOWNLOAD.IMAGES,
          label: downloadAsJpg ? 'JPG Images' : 'JDA Images',
          value: imageLocations.length,
        })
      }
    }
    closeFunction()
  }
  return (
    <StyledDialog isVisible={visible} onRefuseDialog={closeFunction}>
      <Container padding="dense">
        <StyledGridContainer direction="column" spacing="dense">
          <GridItem>
            <Caption className="download-modal-caption">
              {numberofRowsSelected} DPCIs Selected
            </Caption>
            <SubCaption className="download-modal-subcaption">
              Select to download images and/or dimensions
            </SubCaption>
          </GridItem>
          <GridItem>
            <Divider />
            <StyledFormCheckbox
              className="checkbox-download-images"
              label="Download Images"
              onChange={(event) => {
                setImageDownload(event.target.checked)
              }}
            />
            <StyledFormCheckbox
              className="checkbox-download-dimensions"
              label="Download Dimensions"
              onChange={(event) => {
                setDimensionDownload(event.target.checked)
              }}
            />
            <Divider />
          </GridItem>
          <GridItem>
            <GridContainer spacing="none">
              <GridItem>
                <Button
                  raised
                  className="download-modal-download-button"
                  onClick={() => {
                    handleDownload()
                  }}
                >
                  Download
                </Button>
              </GridItem>
              <GridItem>
                <DownloadButton
                  className="download-modal-cancel-button"
                  onClick={() => {
                    closeFunction()
                  }}
                >
                  Cancel
                </DownloadButton>
              </GridItem>
              {ImageDownload && (
                <GridItem>
                  <StyledFormCheckboxJpg
                    label="Download as JPG"
                    onChange={(event) => {
                      setDownloadAsJpg(event.target.checked)
                    }}
                  />
                </GridItem>
              )}
            </GridContainer>
          </GridItem>
        </StyledGridContainer>
      </Container>
    </StyledDialog>
  )
}

export default connect(
  ({ images }) => ({ images }),
  (dispatch) => ({
    getImages: (query, downloadAsJpg) =>
      dispatch(getImages(query, downloadAsJpg)),
  })
)(DownloadModal)
