import { NavBar } from './styled'
import { Logo, Logout } from './icons'
import { GridContainer, GridItem } from 'canvas-ui-react'

export default function index() {
  return (
    <NavBar padding="dense">
      <GridContainer direction="column" align="center" spacing="expanded">
        <GridItem>
          <Logo />
        </GridItem>
        <GridItem>
          <Logout />
        </GridItem>
      </GridContainer>
    </NavBar>
  )
}
