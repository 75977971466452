import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, GridItem } from 'canvas-ui-react'
import styled from 'styled-components'

export const SubHeading = styled.div`
  font-size: 1em;
  color: gray;
`
SubHeading.displayName = 'SubHeading'

export const TextArea = styled.div`
  padding-top: 10px;
`
TextArea.displayName = 'TextArea'

export const StyledIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin-left: 10px;
`
StyledIcon.displayName = 'StyledIcon'

export const StyledDialog = styled(Dialog)`
  bottom: 50%;
`
export const StyledGridItem = styled(GridItem)`
  width: 700px;
`
