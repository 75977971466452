import FileSaver from 'file-saver'
import JSZip from 'jszip'
export const downloadImages = async (
  images,
  downloadAsJpg,
  completeNameRequired
) => {
  var zip = new JSZip()
  images.forEach((image) => {
    let filename = image.headers
      .get('content-disposition')
      .split('filename=')[1]

    downloadAsJpg
      ? zip
          .folder(filename.substring(0, 5))
          .file(
            getFileName(filename, completeNameRequired).concat('.jpg'),
            image.blob(),
            {
              binary: true,
            }
          )
      : zip
          .folder(filename.substring(0, 5))
          .file(getFileName(filename, completeNameRequired), image.blob(), {
            binary: true,
          })
  })
  await zip
    .generateAsync({
      type: 'blob',
    })
    .then(function (content) {
      FileSaver.saveAs(content, 'product Images ' + new Date() + '.zip')
    })
    .catch((error) => {
      alert(error)
    })
}

export const getFileName = (filename, completeNameRequired) => {
  if (completeNameRequired) {
    return filename
  }
  return filename.replace(filename.substring(0, 5), 0)
}
