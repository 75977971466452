import { useState } from 'react'
import { Form, GridContainer, GridItem } from 'canvas-ui-react'
import { HeaderContainer, SearchBar } from './styled'
import CopyPasteModal from '../Modals/CopyPasteModal'
import { connect } from 'react-redux'
import { getItemsImage } from 'ducks/items'
import { parseSearchDpci } from 'util/format'
import { trackEvent } from 'components/Analytics/trackAnalytics'
import { eventsMap } from 'components/Analytics/eventsMap'
const Header = ({ getItemsImage }) => {
  const [searchString, setSearchString] = useState('')
  const [open, setOpen] = useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClickClose = () => {
    setOpen(false)
  }
  const handleSearch = (searchString) => {
    if (searchString.length) {
      let searchList = parseSearchDpci(searchString)
      getItemsImage(searchList)
      setOpen(false)
      setSearchString('')
      trackEvent({
        ...eventsMap.SEARCH,
        value: searchList.length,
      })
    }
  }
  return (
    <HeaderContainer padding="expanded">
      {' '}
      <GridContainer justify="space-between" spacing="none">
        <GridItem>
          <GridContainer spacing="none">
            <GridItem>
              <SearchBar>
                <Form
                  className="form_item_search"
                  onSubmit={(event) => {
                    event.preventDefault()
                    handleSearch(searchString)
                  }}
                >
                  <Form.Input
                    className="item_search"
                    placeholder="Search By DPCI"
                    value={searchString}
                    onChange={(event) => {
                      setSearchString(event.target.value)
                    }}
                  />
                </Form>
              </SearchBar>
            </GridItem>
            <GridItem>
              <CopyPasteModal
                className="copy-paste-search-modal"
                handleSearch={handleSearch}
                open={open}
                handleClickClose={handleClickClose}
                handleClickOpen={handleClickOpen}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
        {/* <GridItem>
          <Button outlined>UPLOAD DPCI</Button>
        </GridItem> */}
      </GridContainer>
    </HeaderContainer>
  )
}

export default connect(null, (dispatch) => ({
  getItemsImage: (query) => dispatch(getItemsImage(query)),
}))(Header)
