import {
  Container,
  ExpandableSection,
  FormField,
  GridContainer,
} from 'canvas-ui-react'
import { PegholeItem } from './styled'
import { useState, useEffect } from 'react'

export default function Pegholes({ pegholes, setPegholes, setEditingStarted }) {
  const [pegholeExpand, setPegholeExpand] = useState(false)

  useEffect(() => {
    const arePegholesPresent = () => {
      if (
        pegholes.first.left !== '' ||
        pegholes.first.down !== '' ||
        pegholes.second.left !== '' ||
        pegholes.second.down !== ''
      ) {
        return true
      }
      return false
    }
    setPegholeExpand(arePegholesPresent())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pegholes.dpci])

  const firstPegholeLeftOffsetUpdate = (value) => {
    setEditingStarted(true)
    setPegholes({
      ...pegholes,
      first: {
        ...pegholes.first,
        left: validPeghole(value),
      },
    })
  }
  const firstPegholeDownOffsetUpdate = (value) => {
    setEditingStarted(true)
    setPegholes({
      ...pegholes,
      first: {
        ...pegholes.first,
        down: validPeghole(value),
      },
    })
  }
  const secondPegholeLeftOffsetUpdate = (value) => {
    setEditingStarted(true)
    setPegholes({
      ...pegholes,
      second: {
        ...pegholes.second,
        left: validPeghole(value),
      },
    })
  }
  const secondPegholeDownOffsetUpdate = (value) => {
    setEditingStarted(true)
    setPegholes({
      ...pegholes,
      second: {
        ...pegholes.second,
        down: validPeghole(value),
      },
    })
  }
  const validPeghole = (value) => {
    if (value < 0 || value === '' || isNaN(value)) {
      return ''
    }
    return value
  }

  return (
    <div>
      <ExpandableSection
        padding="none"
        expanded={pegholeExpand}
        onExpand={() => {
          setPegholeExpand(!pegholeExpand)
        }}
      >
        <p>PEGHOLES</p>
        <ExpandableSection.Content
          style={{
            paddingLeft: '15px',
          }}
        >
          <div>
            <Container>
              <GridContainer direction="row" justify="dense">
                <PegholeItem>
                  Peghole one <p />
                  <FormField
                    label="Left (In)"
                    type="number"
                    value={pegholes.first.left}
                    onChange={(e) => {
                      firstPegholeLeftOffsetUpdate(e.target.value)
                    }}
                  />
                  <FormField
                    label="Down (In)"
                    type="number"
                    value={pegholes.first.down}
                    onChange={(e) => {
                      firstPegholeDownOffsetUpdate(e.target.value)
                    }}
                  />
                </PegholeItem>

                <PegholeItem>
                  Peghole two <p />
                  <FormField
                    label="Left (In)"
                    type="number"
                    value={pegholes.second.left}
                    onChange={(e) => {
                      secondPegholeLeftOffsetUpdate(e.target.value)
                    }}
                  />
                  <FormField
                    label="Down (In)"
                    type="number"
                    value={pegholes.second.down}
                    onChange={(e) => {
                      secondPegholeDownOffsetUpdate(e.target.value)
                    }}
                  />
                </PegholeItem>
              </GridContainer>
            </Container>
          </div>
        </ExpandableSection.Content>
      </ExpandableSection>
    </div>
  )
}
