import { useEffect } from 'react'
import 'canvas-ui-css-marquette'
import { requestConfig } from './ducks/config'
import { connect } from 'react-redux'
import { EnvProvider } from '@praxis/component-runtime-env'
import { GetValueByKeyInNestedObject } from './util/objectUtils'
import { AuthProvider, ProtectedRoute, useAuth } from '@praxis/component-auth'
import routes from './util/routes'
import { BrowserRouter, Switch } from 'react-router-dom'
import Header from './components/Header'
import { GridItem, GridContainer } from 'canvas-ui-react'
import SideNavigation from './components/SideNavigation'
import { MainGridItem, NavGridItem } from './styled'
import LoginModal from './components/Modals/LoginModal'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { HelveticaForTarget } from '@enterprise-ui/component-font'

toast.configure({
  autoClose: 10000,
  newestOnTop: true,
})

const Main = ({ config }) => {
  const auth = useAuth()
  const { isAuthenticated } = auth

  return (
    <BrowserRouter>
      <HelveticaForTarget variants={['n4', 'n5', 'n7']} />
      <GridContainer spacing="none">
        <NavGridItem>
          <SideNavigation />
        </NavGridItem>
        <MainGridItem>
          <GridContainer direction="column" spacing="none">
            <GridItem>
              <Header />
            </GridItem>
            {isAuthenticated() ? (
              <GridItem>
                <Switch>
                  {Object.values(routes).map(({ path, component }) => {
                    return (
                      <ProtectedRoute
                        key={path}
                        exact
                        allowed={config.adGroup}
                        path={path}
                        component={component}
                      />
                    )
                  })}
                </Switch>
              </GridItem>
            ) : (
              <LoginModal />
            )}
          </GridContainer>
        </MainGridItem>
      </GridContainer>
    </BrowserRouter>
  )
}

const App = ({ config, _requestConfig }) => {
  useEffect(() => {
    _requestConfig(window.location.origin)
  }, [_requestConfig])

  return (
    <EnvProvider>
      {GetValueByKeyInNestedObject(config, 'clientId') && (
        <AuthProvider
          clientId={config.auth.clientId}
          authorizationUrl={config.auth.authorizationUrl}
          logoutUrl={config.auth.logoutUrl}
        >
          <Main config={config} />
        </AuthProvider>
      )}
    </EnvProvider>
  )
}

export default connect(
  ({ config }) => ({ config }),
  (dispatch) => ({
    _requestConfig: (url) => dispatch(requestConfig(url)),
  })
)(App)
