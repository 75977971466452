import { Form, GridContainer, GridItem } from 'canvas-ui-react'
import { connect } from 'react-redux'
import { HORIZONTAL, nest_options, VERTICAL } from 'util/constants'
import {
  Dpci,
  NestContainerGridItem,
  NestGridItem,
  NestTitle,
  NestValueGridItem,
} from './styled'
// import bear from 'img/bear.svg'
import { formatDpci } from 'util/format'
import bear from 'img/bear.svg'
import { useEffect, useState } from 'react'

export const EditHeader = ({
  dpci,
  lastUpdated,
  lastUpdatedUser,
  nest,
  setNest,
  images,
  setEditingStarted,
  item,
}) => {
  const [headerImgURL, setHeaderImgURL] = useState('')
  useEffect(() => {
    const setHeaderImage = () => {
      if (item && item.image) {
        setHeaderImgURL(item.image)
      } else {
        setHeaderImgURL(bear)
      }
    }
    setHeaderImage()
  }, [item])

  const isThereNest = () => {
    if (nest.direction === HORIZONTAL || nest.direction === VERTICAL) {
      return true
    } else {
      return false
    }
  }

  return (
    <GridContainer align="center" spacing="none">
      <GridItem>
        <img
          src={headerImgURL}
          alt="front"
          height="145px"
          width="110px"
          className="edit-header-image"
        />
      </GridItem>
      <GridItem style={{ marginLeft: '20px' }}>
        <GridContainer direction="column" spacing="none">
          <GridItem>
            <Dpci className="edit-header-dpci">{formatDpci(dpci)}</Dpci>
          </GridItem>
          <GridItem>
            Dimensions last updated at :{' '}
            <span className="edit-last-updated-date">{lastUpdated}</span> by{' '}
            <span className="edit-last-updated-user">{lastUpdatedUser}</span>
          </GridItem>
          <NestContainerGridItem>
            <Form.Label>
              <NestTitle>Nesting</NestTitle>
            </Form.Label>
            <GridContainer spacing="none">
              <NestGridItem>
                <Form.Select
                  options={nest_options}
                  value={nest.direction}
                  onChange={(event) => {
                    setEditingStarted(true)
                    setNest({
                      ...nest,
                      direction: nest_options[event.target.value].value,
                    })
                  }}
                />
              </NestGridItem>
              {isThereNest() && (
                <NestValueGridItem>
                  <Form.Input
                    type="number"
                    value={nest.value}
                    onChange={(event) => {
                      setEditingStarted(true)
                      setNest({ ...nest, value: event.target.value })
                    }}
                  />
                </NestValueGridItem>
              )}
            </GridContainer>
          </NestContainerGridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  )
}
export default connect(({ images }) => ({ images }))(EditHeader)
