import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'

const FontAwesomeWrapper = styled(FontAwesomeIcon)`
  cursor: pointer;
`

export default function ActionRenderer() {
  return (
    <div>
      <FontAwesomeWrapper icon={faEllipsisV} />
    </div>
  )
}
