import { GridItem } from 'canvas-ui-react'
import styled from 'styled-components'

export const NavGridItem = styled(GridItem)`
  width: 5%;
  height: 100vh;
`
NavGridItem.displayName = 'NavGridItem'

export const MainGridItem = styled(GridItem)`
  width: 95%;
  height: 100vh;
`
MainGridItem.displayName = 'MainGridItem'
