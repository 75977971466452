import { REQUEST_SUCCEEDED, REQUEST_FAILED } from './requests'
import { call, put, takeEvery, all, select } from 'redux-saga/effects'
import { requestFailed, requestSucceeded } from './requests'
import { downloadImages } from 'util/downloadImages'
import { getUser } from './user'
import { downloadFile } from 'util/request'
import Notify, { TYPE } from 'components/Notify'
const initialState = {
  data: {},
  errors: {},
  lastFetchedTimestamp: null,
}
export const GET_IMAGES = 'requests/GET_IMAGES'
export const RESET_IMAGES = 'requests/RESET_IMAGES'
export const DOWNLOAD_IMAGES = 'requests/DOWNLOAD_IMAGE'
export const IMAGE = 'IMAGE'

export default function reducer(state = initialState, action) {
  const { type, requestType, resourceType, response } = action
  switch (type) {
    case REQUEST_SUCCEEDED:
      if (resourceType === IMAGE && requestType === GET_IMAGES) {
        return handleRequestSucceeded(requestType, state)
      } else return state
    case REQUEST_FAILED:
      if (resourceType === IMAGE && requestType === GET_IMAGES) {
        return handleRequestFailed(requestType, response, state)
      } else return state
    default:
      return state
  }
}
const handleRequestSucceeded = (requestType, state) => {
  switch (requestType) {
    case GET_IMAGES:
      return {
        ...state,
        lastFetchedTimestamp: Date.now(),
      }
    default:
      return state
  }
}
const handleRequestFailed = (requestType, response, state) => {
  switch (requestType) {
    case GET_IMAGES:
      return {
        ...state,
        error: response,
        lastFetchedTimestamp: null,
      }
    default:
      return state
  }
}
//Actions
export function getImages(imagesFileLocation, downloadAsJpg) {
  return {
    type: GET_IMAGES,
    resourceType: IMAGE,
    imagesFileLocation,
    downloadAsJpg,
  }
}
export function getImagesDownload(itemImages, downloadAsJpg) {
  return {
    type: DOWNLOAD_IMAGES,
    resourceType: IMAGE,
    itemImages,
    downloadAsJpg,
  }
}
export function resetImages(requestType) {
  return {
    type: RESET_IMAGES,
    requestType,
  }
}
//Operations
const getConfig = ({ config }) => config
export const sagas = [
  presentationImagesWatchers,
  presentationImagesDownloadWatcher,
]

export function* presentationImagesWatchers() {
  yield takeEvery(GET_IMAGES, getImagesWorker)
}

function* getImagesWorker(action) {
  const { type, resourceType, imagesFileLocation, downloadAsJpg } = action
  let itemImages = []
  try {
    const envConfig = yield select(getConfig)
    const { GATEWAY_API_KEY } = envConfig
    const options = {
      method: 'GET',
      headers: {
        ...(yield call(authenticateRequest)),
      },
    }
    const callsToMake = imagesFileLocation.map((fileLoc) => {
      let url = `${envConfig.POG_IMAGE_SVC}/images/${fileLoc}?key=${GATEWAY_API_KEY}`
      return call(downloadFile, url, options)
    })
    itemImages = yield all(callsToMake)

    itemImages = itemImages.filter((image) => image.ok)

    if (itemImages.length > 0) {
      yield put(requestSucceeded(GET_IMAGES, IMAGE, itemImages))
      yield put(getImagesDownload(itemImages, downloadAsJpg))
    } else {
      yield put(requestFailed(type, resourceType, imagesFileLocation))
      Notify({
        message: `No images were found`,
        type: TYPE.ERROR,
      })
    }
  } catch (e) {
    console.error(e)
    yield put(requestFailed(type, resourceType, e, imagesFileLocation))
    Notify({
      message: `Failed fetch images`,
      type: TYPE.ERROR,
    })
  }
}

export function* presentationImagesDownloadWatcher() {
  yield takeEvery(DOWNLOAD_IMAGES, getImagesDownloadWorker)
}
function* getImagesDownloadWorker(action) {
  const { type, resourceType, itemImages, downloadAsJpg } = action
  const envConfig = yield select(getConfig)
  const { IS_POL } = envConfig
  if (type === DOWNLOAD_IMAGES && resourceType === IMAGE) {
    try {
      yield downloadImages(itemImages, downloadAsJpg, !IS_POL)
      yield put(requestSucceeded(type, resourceType, 'Download finished'))
      yield put(resetImages())
    } catch (e) {
      console.log(e)
      yield put(requestFailed(type, resourceType, 'Download failed'))
    }
  }
}

export function* authenticateRequest() {
  const user = yield select(getUser)
  return user ? { Authorization: user.accessToken } : {}
}
