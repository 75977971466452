export const UNIT = 'UNIT'
export const ALTERNATE = 'ALTERNATE'
export const TRAY = 'TRAY'
export const DISPLAY = 'DISPLAY'
export const CASE = 'CASE'
export const LOOSE = 'LOOSE'
export const FRONT = 'Front'
export const BACK = 'Back'
export const LEFT = 'Left'
export const RIGHT = 'Right'
export const TOP = 'Top'
export const BOTTOM = 'Bottom'
export const HORIZONTAL = 'HORIZONTAL'
export const VERTICAL = 'VERTICAL'
export const SELECT_ONE = 'Select one'
export const EDIT_TABLE = 'edit-table'
export const SEARCH_RESULTS_TABLE = 'result-table'
export const YES = 'YES'
export const NO = 'NO'

export const nest_options = [
  {
    value: HORIZONTAL,
    label: 'Horizontal',
  },
  {
    value: VERTICAL,
    label: 'Vertical',
  },
  {
    value: null,
    label: 'None',
  },
]
export const mock_options = [
  {
    value: true,
    label: YES,
  },
  {
    value: false,
    label: NO,
  },
  {
    value: null,
    label: '-NOT SET-',
  },
]
