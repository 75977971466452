import { GridItem } from 'canvas-ui-react'
import styled from 'styled-components'

export const OptionalDescription = styled.div`
  font-size: 13px;
  color: gray;
`
OptionalDescription.displayName = 'OptionalDescription'

export const TableWrapper = styled.div`
  bottom: ${(props) => (props.tableExpand ? '30px' : '100px')};
`
TableWrapper.displayName = 'TableWrapper'

export const DownloadGridItem = styled(GridItem)``

export const DimensionEditor = styled.div`
  padding-top: 8px;
`
