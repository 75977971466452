import { NestRenderer, OptionalDescriptionAdder } from 'util/columnDefHelper'
import ActionRenderer from './ActionRenderer'
import { DimensionCellEditor, DimensionCellRenderer } from './cellRenderers'

export const frameworkComponents = {
  optionalDescriptionAdder: OptionalDescriptionAdder,
  actionsRenderer: ActionRenderer,
  nestRenderer: NestRenderer,
  dimensionCellRenderer: DimensionCellRenderer,
  dimensionCellEditor: DimensionCellEditor,
}
