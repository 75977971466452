import { Container } from 'canvas-ui-react'
import styled from 'styled-components'

export const HeaderContainer = styled(Container)`
  box-shadow: 0 5px 15px -10px #000000;
  background-color: #f7f7f7;
  position: relative;
`
HeaderContainer.displayName = 'HeaderContainer'

export const SearchBar = styled.div`
  width: 300px;
`
SearchBar.displayName = 'SearchBar'
