import { toast } from 'react-toastify'

export const TYPE = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  DEFAULT: 'default',
}

const Notify = ({ message, type, description = '' }) => {
  switch (type) {
    case 'success':
      toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500,
      })
      break
    case 'error':
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500,
      })
      break
    case 'warning':
      toast.warning(message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      break
    case 'info':
      toast.info(message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      break
    default:
      toast.default(message, {
        position: toast.POSITION.TOP_RIGHT,
      })
      break
  }
}

export default Notify
