import { DOWNLOAD_IMAGES, GET_IMAGES, IMAGE } from './images'
import { GET_ITEMS, GET_ITEMS_IMAGE, ITEM } from './items'
import { REQUEST_FAILED, REQUEST_SUCCEEDED } from './requests'

export const initialState = []

export default function reducer(state = initialState, action) {
  const { type, resourceType } = action
  switch (type) {
    case GET_ITEMS:
      return [...state, ITEM]
    case GET_ITEMS_IMAGE:
      return [...state, ITEM]
    case GET_IMAGES:
      return [...state, IMAGE]
    case DOWNLOAD_IMAGES:
      return [...state, IMAGE]
    case REQUEST_SUCCEEDED:
      return [...state].filter((r) => r !== resourceType)
    case REQUEST_FAILED:
      return [...state].filter((r) => r !== resourceType)
    default:
      return state
  }
}
