import { call, put, takeEvery } from 'redux-saga/effects'
import request from 'util/request'
import { REQUEST_SUCCEEDED, requestSucceeded, requestFailed } from './requests'
import ReactGA from 'react-ga'

export const REQUEST_CONFIG = 'config/REQUEST_CONFIG'
const hostname = window && window.location && window.location.hostname

const isPol =
  hostname === 'pid.perf.partnersonline.com' ||
  hostname === 'pid.partnersonline.com'

export function requestConfig(url) {
  return {
    type: REQUEST_CONFIG,
    url,
  }
}

export const initialState = {}

export default function reducer(state = initialState, action) {
  const { type, resourceType, response } = action
  switch (type) {
    case REQUEST_SUCCEEDED:
      return resourceType === 'CONFIG' ? response : state
    default:
      return state
  }
}

export function* configSaga() {
  yield takeEvery(REQUEST_CONFIG, requestConfigWorker)
}

export const sagas = [configSaga]

export function* requestConfigWorker({ type, url }) {
  try {
    const response = yield call(
      request,
      isPol ? `${url}/app-environment-pol` : `${url}/app-environment`
    )
    yield put(requestSucceeded(type, 'CONFIG', response))
    ReactGA.initialize(
      response.googleAnalytics.TRACKING_ID,
      response.googleAnalytics.CONFIGURATIONS
    )
  } catch (e) {
    console.error(e)
    yield put(requestFailed(type, 'CONFIG', e))
  }
}

export const config = ({ config }) => config
