import logo from 'img/hexagon.svg'
import logout from 'img/logout.svg'
import { LogoStyle, Icon, IconImage, IconText } from './styled'
import { useAuth } from '@praxis/component-auth'
export const Logo = () => (
  <Icon
    onClick={() => {
      window.location.reload()
    }}
  >
    <LogoStyle src={logo} />
    <IconText>Home</IconText>
  </Icon>
)

export const Logout = () => {
  const auth = useAuth()
  return (
    <Icon onClick={() => auth.logout()}>
      <IconImage src={logout} />
      <IconText>Logout</IconText>
    </Icon>
  )
}
