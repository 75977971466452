import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GridContainer, GridItem } from 'canvas-ui-react'
import styled from 'styled-components'

export const ResultsGridContainer = styled(GridContainer)`
  width: 100%;
`

export const GridItemTable = styled(GridItem)`
  margin-left: 20px;
  margin-right: 20px;
`
export const NotFoundMsg = styled.div`
  color: gray;
  font-style: italic;
`
export const ErrorIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`
export const ItemInfo = styled.div`
  height: 80px;
  margin-left: 20px;
  line-height: 0px;
`
export const IconGridItem = styled(GridItem)`
  margin-left: 10px;
`

export const TableWrapper = styled.div`
  //The position is fixed so that download bar doesnt hide any part of the table
  position: fixed;
  top: 160px;
  left: 7%;
  right: 20px;
  bottom: ${(props) => (props.tableExpand ? '30px' : '100px')};
  transition: height 200ms;
`
