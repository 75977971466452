import { Form } from 'canvas-ui-react'
import { useState, forwardRef, useImperativeHandle, createRef } from 'react'
import { DimensionEditor } from './styled'

export const DimensionCellRenderer = ({ value }) => {
  return (
    <div>
      <Form.Input defaultValue={value} />
    </div>
  )
}
export const DimensionCellEditor = forwardRef((props, ref) => {
  const inputRef = createRef()
  const [dimension, setDimension] = useState(props.value)

  const dimensionChange = (updatedDimension) => {
    if (updatedDimension === '' || updatedDimension < 0) {
      setDimension(0)
    } else {
      setDimension(updatedDimension)
    }
  }

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return parseFloat(dimension)
      },
      afterGuiAttached: () => {
        setDimension(props.value)
        inputRef.current.focus()
      },
    }
  })
  return (
    <DimensionEditor>
      <Form.Input
        ref={inputRef}
        defaultValue={dimension}
        type="number"
        onChange={(event) => dimensionChange(event.target.value)}
      />
    </DimensionEditor>
  )
})
