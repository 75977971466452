import { useState, useEffect, useCallback } from 'react'
import {
  Button,
  Container,
  GridContainer,
  GridItem,
  Drawer,
} from 'canvas-ui-react'
import Table from 'components/Table'
import Header from './header'
import {
  ActionGridItem,
  EditTable,
  HeaderGridItem,
  Main,
  MockGridItem,
  PegholeGridItem,
  TableGridItem,
} from './styled'
import {
  getRowsForEditTable,
  getUpdatedNest,
  getUpdatedPegholes,
} from 'util/format'
import { getMock, getNest, getPegholes } from 'util/utils'
import { connect } from 'react-redux'
import { columnDefs, defaultColDef } from './columnDefs'
import { EDIT_TABLE } from 'util/constants'
import { putItem } from 'ducks/items'
import Pegholes from './Pegholes'
import Mock from './Mock'

const EditModal = ({ visible, setVisible, dpci, items, putItem }) => {
  const [editingStarted, setEditingStarted] = useState(false)

  let lastUpdated = items.data[dpci]
    ? items.data[dpci].dimensions_updated_date
    : 0
  let lastUpdatedUser = items.data[dpci]
    ? items.data[dpci].dimensions_update_user_id
    : 0

  const [nest, setNest] = useState({ value: 0, direction: null })

  const [pegholes, setPegholes] = useState({
    dpci: dpci,
    first: { left: '', down: '' },
    second: { left: '', down: '' },
  })

  const [mockItem, setMockItem] = useState({
    mock_dimension: null,
    mock_image: null,
  })

  const [gridApi, setGridApi] = useState('')

  const setTableData = useCallback(() => {
    if (gridApi && items.data[dpci]) {
      gridApi.setRowData(getRowsForEditTable(items.data[dpci]))
    }
  }, [gridApi, items, dpci])

  useEffect(() => {
    const setEditTable = () => {
      if (items.data[dpci]) {
        setEditingStarted(false)
        setNest(getNest(items.data[dpci]))
        setPegholes(getPegholes(items.data[dpci]))
        setMockItem(getMock(items.data[dpci]))

        //setting rows in table
        setTableData()
      }
    }
    if (visible) {
      setEditTable()
    }
  }, [dpci, items, setTableData, visible])

  const getAllRows = () => {
    let rowData = []
    if (gridApi) {
      gridApi.forEachNode((node) => rowData.push(node.data))
    }
    return rowData
  }

  const getUpdatedDimensions = (tableData) => {
    let updatedDimensions = []
    for (let row of tableData) {
      let dimension = {
        style: row.style,
        width: parseFloat(row.width),
        depth: parseFloat(row.depth),
        height: parseFloat(row.height),
      }
      updatedDimensions.push(dimension)
    }
    return updatedDimensions
  }

  const saveUpdates = () => {
    if (editingStarted) {
      let tableData = getAllRows()
      const updatedNest = getUpdatedNest(nest)
      const updatedPegholes = getUpdatedPegholes(pegholes)
      const updatedMockValues = mockItem

      //saving
      let updatedDimensions = getUpdatedDimensions(tableData)

      let updatedItemDetails = {
        dimensions: updatedDimensions,
        ...updatedNest,
        ...updatedPegholes,
        ...updatedMockValues,
      }
      putItem(dpci, updatedItemDetails)
      setVisible(false)
    }
  }

  const cancelUpdates = () => {
    setVisible(false)
  }
  return (
    <div>
      <Drawer
        isVisible={visible}
        sm={12}
        md={8}
        lg={6}
        xl={5}
        onRequestClose={() => {}}
      >
        <GridContainer direction="column">
          <Main>
            <HeaderGridItem>
              <Container>
                <Header
                  item={items.data[dpci]}
                  dpci={dpci}
                  lastUpdated={lastUpdated}
                  lastUpdatedUser={lastUpdatedUser}
                  nest={nest}
                  setNest={setNest}
                  setEditingStarted={setEditingStarted}
                />
              </Container>
            </HeaderGridItem>
            <GridItem>
              <EditTable>
                <Container>
                  <TableGridItem>
                    <Table
                      onCellEditingStarted={() => {
                        setEditingStarted(true)
                      }}
                      tableName={EDIT_TABLE}
                      getGridApi={setGridApi}
                      domLayout="autoHeight"
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                      stopEditingWhenGridLosesFocus={true}
                    />
                  </TableGridItem>
                </Container>
              </EditTable>
            </GridItem>
            <br />
            <GridItem>
              <GridContainer
                direction="row"
                justify="space-between"
                spacing="none"
              >
                <PegholeGridItem>
                  <Pegholes
                    pegholes={pegholes}
                    setPegholes={setPegholes}
                    dpci={dpci}
                    setEditingStarted={setEditingStarted}
                  />
                </PegholeGridItem>
                <MockGridItem>
                  <Mock
                    mockItem={mockItem}
                    setMockItem={setMockItem}
                    setEditingStarted={setEditingStarted}
                    dpci={dpci}
                  />
                </MockGridItem>
              </GridContainer>
            </GridItem>
          </Main>
          <ActionGridItem>
            <Container>
              <GridContainer direction="row-reverse" align="center">
                <GridItem>
                  <Button
                    className="save-update-button"
                    raised
                    onClick={() => {
                      saveUpdates()
                    }}
                    disabled={!editingStarted}
                  >
                    SAVE CHANGES
                  </Button>
                </GridItem>
                <GridItem>
                  <Button
                    className="cancel-update-button"
                    onClick={cancelUpdates}
                  >
                    CANCEL & DISCARD
                  </Button>
                </GridItem>
              </GridContainer>
            </Container>
          </ActionGridItem>
        </GridContainer>
      </Drawer>
    </div>
  )
}

export default connect(
  ({ items, images }) => ({ items, images }),
  (dispatch) => ({
    putItem: (dpci, updatedItemDetails) =>
      dispatch(putItem(dpci, updatedItemDetails)),
  })
)(EditModal)
