export const columnDefs = [
  { field: 'edited', hide: true },
  {
    field: 'style',
    colId: 'style',
    flex: 1,
    editable: false,
  },
  {
    headerName: 'Dimensions',
    children: [
      {
        headerName: 'Height(in)',
        field: 'height',
        width: 150,
        cellRenderer: 'dimensionCellRenderer',
        cellEditor: 'dimensionCellEditor',
      },
      {
        headerName: 'Width(in)',
        field: 'width',
        width: 150,
        cellRenderer: 'dimensionCellRenderer',
        cellEditor: 'dimensionCellEditor',
      },
      {
        headerName: 'Depth(in)',
        field: 'depth',
        width: 150,
        cellRenderer: 'dimensionCellRenderer',
        cellEditor: 'dimensionCellEditor',
      },
    ],
  },
]

export const defaultColDef = {
  suppressMenu: true,
  resizable: true,
  editable: true,
  singleClickEdit: true,
}
