import { Dialog } from 'canvas-ui-react'
import styled from 'styled-components'

export const StyledDialog = styled(Dialog)`
  bottom: 50%;
`
export const DialogHeading = styled.p`
  font-size: 20px;
  font-weight: normal;
`
export const DpciList = styled.p`
  font-weight: normal;
`
