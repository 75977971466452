import { Container } from 'canvas-ui-react'
import styled from 'styled-components'

export const NavBar = styled(Container)`
  background-color: #333333;
  height: 100vh;
  overflow: hidden;
`
NavBar.displayName = 'NavBar'

export const LogoStyle = styled.img`
  width: 40px;
  margin-top: 20px;
  alt: Refresh;
  transition: transform 0.5s ease-in;
  &:hover {
    transform: rotate(180deg);
  }
`
export const Icon = styled.div`
  cursor: pointer;
`
export const IconImage = styled.img`
  display: block;
  margin: auto;
`
export const IconText = styled.div`
  color: white;
  text-align: center;
  font-size: 14px;
`
